.App {
  text-align: center;
  color: #333;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App .main-image {
  width: 100%;
  height: 30em;
  opacity: 0.75;
  object-fit: cover;
}

.content-block {
  min-height: 10em;
  margin-top: 4em;
}

h1,
h2 {
  font-family: 'Caveat', cursive;
}

h1 {
  font-size: 4em;
  font-weight: bold;
}

h2 {
  font-size: 3em;
}

p,
ul {
  font-family: 'Quicksand', sans-serif;
}

li {
  margin-top: 1em;
}

header a {
  color: #333;
}

header a:hover {
  color: #888;
}

.header-icon {
  font-size: 4em;
}

.nav-icon {
  font-size: 2em;
}

.nav-item-text {
  font-size: 2em;
  margin-left: 2em;
}

.float {
  position: fixed;
  font-size: 2.5em;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 80px;
  background-color: #333;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  z-index: 1000;
}

/* .card a {
  text-decoration: none;
  color: #333 !important;
} */

.collage-img {
  width: 8em;
  height: 8em;
  object-fit: cover;
}

.us-container {
  width: 100%;
  height: 30em;
  opacity: 0.7;
  object-fit: cover;
  overflow: hidden;
  border-radius: 10px;
}

.card-img-top {
  opacity: 0.6;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited,
.btn-primary:focus {
  background-color: #333;
  border-color: #333;
  font-size: 1.25em;
}
